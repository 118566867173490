import gql from 'graphql-tag';

export const GetPharmacyQuery = gql`
  query GetPharmacy($ncpdp: String!) {
    pharmacy(ncpdp: $ncpdp) {
      _id
      achRemittanceEmail
      address {
        lineOne
        lineTwo
        city
        county
        state
        zip
        lat
        lon
      }
      affiliationCode
      contact {
        faxNumber
        email
        phoneNumber
      }
      contactPerson {
        email
        firstName
        lastName
        phone
        title
      }
      pharmacyChain {
        name
        npi
      }
      dbaName
      deaExpirationId
      deaRegistrationId
      family
      fedTaxId
      is835FileRequired
      isRelayhealthParticipant
      joinedAt
      legalName
      mailingAddress {
        city
        lineOne
        lineTwo
        state
        zip
      }
      msa
      name
      ncpdp
      npi
      owner {
        firstName
        lastName
      }
      parentOrganization {
        name
      }
      paymentInfo {
        bankAccountNumber
        bankRoutingNumber
        contactEmail
        contactName
        pharmacyNpi
        destinationId
        authorizationAttachments
        remittanceAdviceStartDate
        isPaymentEnabled
        is835Enabled
      }
      pbaEnrollment {
        isPaymentAdjudicationEnabled
        ncpdpProtocolSettings {
          group
          pcn
          pcnGroupDelimiter
          valueToNone
        }
        systemVendor {
          id
          name
        }
      }
      pic {
        firstName
        lastName
      }
      stateTaxId
      taxId
    }
  }
`;
