import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { ClaimFlagSettingsSchema } from '@libs/shared/domain/claim/flag/claim-flag-settings.schema';
import { ClaimFlagsService } from '@services/claim-flags.service';
import { PatientAssistanceRequestViewModel } from '@services/models/patient-assistance-request-view.model';
import { FlagsMap } from '@shared/components/claim-flags/claim-flags-types';

@Component({
  selector: 'app-shared-claim-flags-detail',
  templateUrl: './claim-flags-detail.component.html',
  styleUrl: './claim-flags-detail.component.scss',
})
export class ClaimFlagsDetailComponent {
  private readonly claimFlagsService = inject(ClaimFlagsService);
  readonly flagCategoryCountType!: { value: number; label: string; class: string };
  readonly flagCategoryDetailsType!: { flags: ClaimFlagSettingsSchema[]; label: string; class: string };

  claim = input.required<PatientAssistanceRequestViewModel>();
  flagsListFilterValues = input<string[]>();
  flagsMap = signal<FlagsMap>(this.claimFlagsService.getFlagsMap());
  flagsMapFiltered = computed(() => {
    const flagsListFilterValues = this.flagsListFilterValues();
    if (flagsListFilterValues && flagsListFilterValues.length > 0) {
      const flagsMap = this.flagsMap();
      for (const flagCategory of Object.keys(flagsMap)) {
        flagsMap[flagCategory as keyof typeof flagsMap] = flagsMap[flagCategory as keyof typeof flagsMap].filter(flag =>
          flagsListFilterValues.includes(flag.name),
        );
      }
      return flagsMap;
    }
    return this.flagsMap();
  });
  scanId = signal('');
  flagsScanned = signal(0);
  flagsCount = computed(
    () =>
      this.claim().flagsList?.filter(flag => {
        const flagsListFilterValues = this.flagsListFilterValues();
        if (flagsListFilterValues && flagsListFilterValues.length > 0) {
          return flagsListFilterValues.includes(flag);
        }
        return flag;
      }).length ?? 0,
  );
  flagsIdentity = computed(() => this.claimFlagsService.getFlagsIdenity(this.flagsMapFiltered()));
  flagsValueDescription = signal('');
  economicValue = computed(() => {
    const flagsListFilterValues = this.flagsListFilterValues();
    if (flagsListFilterValues && flagsListFilterValues.length > 0) {
      const filteredFlagAmounts = Object.entries(this.claim().flagsAmounts).filter(([flagKey]) => flagsListFilterValues.includes(flagKey));
      return Math.max(...filteredFlagAmounts.map(flagAmount => flagAmount[1]));
    }
    return this.claim().flagsAmountsValue;
  });

  constructor() {
    effect(() => this.onClaimChange(this.claim()), { allowSignalWrites: true });
  }

  private onClaimChange(claim: PatientAssistanceRequestViewModel) {
    this.scanId.set(new Date(claim.created).getTime().toString().slice(3, 10));
    this.claimFlagsService.getFlagsSettings(claim.tenantId, false).subscribe(flags => {
      this.flagsMap.set(this.claimFlagsService.getFlagsMap(claim, flags));
      this.flagsScanned.set(flags.length);
      this.flagsValueDescription.set(this.claimFlagsService.getFlagsValueDescription(claim, this.flagsMapFiltered()));
    });
  }
}
