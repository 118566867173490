@if (flagsCount() > 0) {
  <div class="scanStatus bg-success text-white d-flex align-items-center gap-3 px-3 py-2 mb-2">
    <div class="flex-grow-1 d-flex align-items-center gap-2">
      <chitin-icon icon="fa-solid fa-circle-info" style="font-size: 1.3rem" />
      <div>Action taken</div>
    </div>
    <div class="flex-grow-1 fw-bold">{{ flagsIdentity() }}</div>
    <div class="scanStatusDivider"></div>
    @if (economicValue()) {
      <div class="fw-bold" style="flex-grow: 0.5">{{ economicValue() | currency }}</div>
      <div class="scanDescription">{{ flagsValueDescription() }}</div>
    } @else {
      <div style="width: 45%"></div>
    }
  </div>
}

<div class="card mb-3">
  <div class="card-body">
    <div class="d-flex">
      <div>
        <div class="d-flex justify-content-between gap-4">
          <div class="fw-bold">Scan Record ID:</div>
          <div class="text-end">{{ scanId() }}</div>
        </div>
        <div class="d-flex justify-content-between gap-4">
          <div class="fw-bold">Conditions Scanned:</div>
          <div class="text-end">{{ flagsScanned() }}</div>
        </div>
        <div class="d-flex justify-content-between gap-4">
          <div class="fw-bold">Detections:</div>
          <div class="text-end">{{ flagsCount() }}</div>
        </div>
      </div>
      <div class="scanVerticalSeparator"></div>
      <div class="d-flex justify-content-evenly align-items-center" style="flex-basis: 0; flex-grow: 1">
        <ng-container
          [ngTemplateOutlet]="flagCategoryCount"
          [ngTemplateOutletContext]="{
            value: flagsMapFiltered()['Vulnerability Detection'].length,
            label: 'Vulnerability\nDetection',
            class: 'security',
          }"
        />
        <ng-container
          [ngTemplateOutlet]="flagCategoryCount"
          [ngTemplateOutletContext]="{
            value: flagsMapFiltered()['Demand Conversion'].length,
            label: 'Demand\nConversion',
            class: 'recognition',
          }"
        />
        <ng-container
          [ngTemplateOutlet]="flagCategoryCount"
          [ngTemplateOutletContext]="{
            value: flagsMapFiltered()['Demand Enablement'].length,
            label: 'Demand\nEnablement',
            class: 'identity',
          }"
        />
      </div>
    </div>

    @if (flagsCount() > 0) {
      @if (flagsMapFiltered()['Vulnerability Detection'].length > 0) {
        <div class="scanHorizontalSeparator my-3"></div>
        <ng-container
          [ngTemplateOutlet]="flagCategoryDetails"
          [ngTemplateOutletContext]="{
            flags: flagsMapFiltered()['Vulnerability Detection'],
            label: 'Vulnerability Detection',
            class: 'security',
          }"
        />
      }
      @if (flagsMapFiltered()['Demand Conversion'].length > 0) {
        <div class="scanHorizontalSeparator my-3"></div>
        <ng-container
          [ngTemplateOutlet]="flagCategoryDetails"
          [ngTemplateOutletContext]="{ flags: flagsMapFiltered()['Demand Conversion'], label: 'Demand Conversion', class: 'recognition' }"
        />
      }
      @if (flagsMapFiltered()['Demand Enablement'].length > 0) {
        <div class="scanHorizontalSeparator my-3"></div>
        <ng-container
          [ngTemplateOutlet]="flagCategoryDetails"
          [ngTemplateOutletContext]="{ flags: flagsMapFiltered()['Demand Enablement'], label: 'Demand Enablement', class: 'identity' }"
        />
      }
      @if (false) {
        <div class="scanHorizontalSeparator my-3"></div>
        <a class="flagsExplainationLink d-block mt-3 text-end">Which flags are displayed?</a>
      }
    }
  </div>
</div>

<ng-template #flagCategoryCount [typed]="flagCategoryCountType" let-value="value" let-label="label" let-class="class">
  <div class="d-flex flex-column align-items-center">
    <div
      class="flagCategoryCount fw-medium d-flex justify-content-center align-items-center"
      [ngClass]="[class, value > 0 ? 'active' : '']"
    >
      <div class="text-white">{{ value }}</div>
    </div>
    <div class="fw-bold text-black text-center mx-2 my-1" style="font-size: 0.8rem; line-height: 0.9rem; white-space: pre">{{ label }}</div>
    <div class="text-black" style="font-size: 0.6rem; margin-top: -2px">{{ value > 0 ? 'Detection' : 'Normal' }}</div>
  </div>
</ng-template>

<ng-template #flagCategoryDetails [typed]="flagCategoryDetailsType" let-flags="flags" let-label="label" let-class="class">
  @for (flag of flags; track $index; let last = $last) {
    <div class="d-flex gap-2">
      <div [ngClass]="class" style="width: 3px"></div>
      <div class="py-2" style="flex-grow: 1">
        <div class="fw-bold">{{ flag.title }}</div>
        @if (flag.description) {
          <div style="margin-top: -5px">{{ flag.description }}</div>
        }
      </div>
    </div>

    @if (!last) {
      <div class="scanHorizontalSeparator my-3"></div>
    }
  }
</ng-template>
