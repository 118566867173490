import { Injectable } from '@angular/core';
import { LookupsService } from '@services/lookups.service';
import { UserService } from '@services/user.service';
import { Column, DataViewItemWithAccess, Filter, PageConfig, View } from '@shared/components/data-view/data-view-types';
import { UserRole } from '@shared/models/user-role.model';
import { IUser } from '@shared/models/user.model';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

const CURRENT_DATAVIEW_VERSION = '3.0.0';
const DATAVIEW_SETTINGS_KEY = 'dataview3';
const DEFAULT_MAP_CONFIG = {
  displayedInfo: {
    basicInfo: true,
    enrollments: true,
    flags: false,
    scorecard: false,
  },
};

@Injectable({
  providedIn: 'root',
})
export class DataViewConfigService {
  constructor(
    private lookupsService: LookupsService,
    private userService: UserService,
  ) {}

  getColumnCategories(page: string, currentUser?: IUser): Observable<Column.DataViewColumnGalleryCategory[]> {
    return this.lookupsService
      .getSettings<Column.DataViewColumnGallerySchema>(DATAVIEW_SETTINGS_KEY, page, 'local')
      .pipe(
        mergeMap(result =>
          this.lookupsService
            .getSettings<Column.DataViewDomainColumnsSchema>(DATAVIEW_SETTINGS_KEY, `${result?.domain}_columns`, 'local')
            .pipe(map(domainResult => this.mapAvailableItems(domainResult?.columnCategories ?? [], currentUser))),
        ),
      );
  }

  getAvailableColumns(page: string, currentUser?: IUser): Observable<Column.DataViewColumn[]> {
    return this.lookupsService.getSettings<Column.DataViewColumnGallerySchema>(DATAVIEW_SETTINGS_KEY, page, 'local').pipe(
      mergeMap(result =>
        this.lookupsService
          .getSettings<Column.DataViewDomainColumnsSchema>(DATAVIEW_SETTINGS_KEY, `${result?.domain}_columns`, 'local')
          .pipe(
            map(domainResult => {
              const columns: Column.DataViewColumnGalleryColumn[] = [];
              for (const domainColumnDefinition of domainResult?.columns ?? []) {
                const pageColumnDefinition = result?.columns.find(column => column.id === domainColumnDefinition.id);
                if (pageColumnDefinition) {
                  columns.push({
                    ...domainColumnDefinition,
                    ...pageColumnDefinition,
                  });
                }
              }
              return this.mapAvailableItems(columns, currentUser);
            }),
          ),
      ),
    );
  }

  getAvailableFilters(page: string, currentUser?: IUser): Observable<Filter.DataViewFilter[]> {
    return this.lookupsService.getSettings<Column.DataViewColumnGallerySchema>(DATAVIEW_SETTINGS_KEY, page, 'local').pipe(
      mergeMap(pageResult =>
        this.lookupsService
          .getSettings<Column.DataViewDomainColumnsSchema>(DATAVIEW_SETTINGS_KEY, `${pageResult?.domain}_columns`, 'local')
          .pipe(
            map(result => {
              const filters: Filter.DataViewFilter[] = [];
              const pageColumns = pageResult?.columns.filter(column => !column.hideFilter) ?? [];
              const pageColumnsIds = pageColumns.map(column => column.id) ?? [];
              const pageColumnsIdsWithRequiredFilters = pageColumns.filter(column => column.requiredFilter).map(column => column.id) ?? [];
              for (const column of result?.columns ?? []) {
                if (
                  column.filter &&
                  this.userHasAccessToItem(column, currentUser) &&
                  this.userHasAccessToItem(column.filter, currentUser)
                ) {
                  filters.push({
                    ...column,
                    id: column.id,
                    categoryId: column.categoryId,
                    display: column.filter.display ?? column.display,
                    field: column.filter.field ?? column.field,
                    options: column.filter.options,
                    maxNumOfOptions: column.filter.maxNumOfOptions,
                    allowSelectAll: column.filter.allowSelectAll,
                    multiple: column.filter.multiple,
                    allowedTypes: column.filter.allowedTypes,
                    required: pageColumnsIdsWithRequiredFilters.includes(column.id),
                  });
                }
              }
              return pageColumnsIds.length ? filters.filter(item => pageColumnsIds.includes(item.id)) : filters;
            }),
          ),
      ),
    );
  }

  getDataViewPageConfig(page: string, currentUser: IUser): Observable<PageConfig.DataViewPageConfig> {
    return forkJoin({
      userViewDefinitions: this.lookupsService.getSettings<PageConfig.DataViewPageConfigSchema>(
        `user-username:${currentUser.username}`,
        `dataview3_${page}_views`,
        'local',
        {
          subject: `user-username:${currentUser.username}`,
          attribute: `dataview_${page}_views`,
        },
      ),
      availableColumns: this.getAvailableColumns(page, currentUser),
      availableFilters: this.getAvailableFilters(page, currentUser),
      defaultViewDefinitons: this.getDataViewDefaultViews(page, currentUser),
    }).pipe(
      map(results => {
        if (results.userViewDefinitions) {
          results.userViewDefinitions = this.updateConfigSchema(
            results.userViewDefinitions,
            results.availableColumns,
            results.availableFilters,
          );
        }
        const favouriteColumns = [];
        for (const favouriteColumnId of results.userViewDefinitions?.favouriteColumnIds ?? []) {
          const favouriteColumn = results.availableColumns.find(availableColumn => availableColumn.id === favouriteColumnId);
          if (favouriteColumn) {
            favouriteColumns.push(favouriteColumn);
          }
        }
        return {
          views: [
            ...this.mapViews(
              results.defaultViewDefinitons,
              results.userViewDefinitions?.pinnedViewIds ?? [],
              results.userViewDefinitions?.unpinnedDefaultViewIds ?? [],
              results.availableColumns,
              results.availableFilters,
            ),
            ...this.mapCustomViews(
              results.userViewDefinitions?.views ?? [],
              results.userViewDefinitions?.pinnedViewIds ?? [],
              results.availableColumns,
              results.availableFilters,
            ),
          ],
          favouriteColumns,
          pinnedViewIds: results.userViewDefinitions?.pinnedViewIds ?? [],
          unpinnedDefaultViewIds: results.userViewDefinitions?.unpinnedDefaultViewIds ?? [],
          configVersion: results.userViewDefinitions?.configVersion,
        };
      }),
    );
  }

  getDataViewDefaultViews(page: string, currentUser: IUser) {
    return this.lookupsService
      .getSettings<View.DefaultView[]>(DATAVIEW_SETTINGS_KEY, `${page}_defaultViews`, 'local')
      .pipe(map(result => (result ?? []).filter(defaultView => this.userHasAccessToItem(defaultView, currentUser))));
  }

  private updateConfigSchema(
    config: PageConfig.DataViewPageConfigSchema,
    availableColumns: Column.DataViewColumn[],
    availableFilters: Filter.DataViewFilter[],
  ): PageConfig.DataViewPageConfigSchema {
    const version = config.configVersion ?? '1.0.0';
    if (version < CURRENT_DATAVIEW_VERSION) {
      // This deletes defaultViews from saved views. In v1 of DataViews, we
      // saved defaultViews into user views instead of passing in references.
      if ('savedViews' in config) {
        config.views = (config.savedViews as View.View[])
          .filter(view => 'isCustom' in view && view.isCustom)
          .map(view => ({ ...view, id: view.label }));
      }
      config.views = config.views.map((view: any) => {
        const mappedColumns = [];
        const appliedColumnFields: any[] =
          (view as any).columns?.flatMap((column: any) => [
            ...(column.type === 'genericDynamic' ? (column?.appliedSettings?.displayFields ?? [column.field]) : [column.field]),
          ]) ?? [];
        for (const appliedColumn of appliedColumnFields) {
          const mappedColumn = availableColumns.find(availableColumn => availableColumn.field === appliedColumn);
          if (mappedColumn) {
            mappedColumns.push({
              id: mappedColumn.id,
              width: appliedColumn.width,
            });
          }
        }

        const mappedFilters: Filter.AppliedDataViewFilterSchema[] = [];
        view.appliedFilters?.forEach((appliedFilter: any) => {
          const mappedFilter = availableFilters.find(availableFilter => availableFilter.field === appliedFilter.field);
          if (mappedFilter) {
            mappedFilters.push({
              id: mappedFilter.id,
              value: appliedFilter.value,
              type: appliedFilter.type,
              timezone: appliedFilter.timezone,
            });
          }
        });

        return {
          id: view.id,
          label: view.label,
          appliedColumns: mappedColumns,
          appliedFilters: mappedFilters,
          isCustom: view.isCustom,
          isPinned: view.isPinned,
          mapConfig: DEFAULT_MAP_CONFIG,
        };
      });
    }
    return {
      ...config,
      configVersion: CURRENT_DATAVIEW_VERSION,
    };
  }

  setDataViewPageConfig(page: string, currentUser: IUser, config: PageConfig.DataViewPageConfig): Observable<Object | undefined> {
    const sharedViews = config.views.filter(
      view =>
        (!('isCustom' in view) || !view.isCustom) &&
        'ownerUsername' in view &&
        view.ownerUsername === this.userService.getCurrentUser().username,
    );
    return this.getDataViewDefaultViews(page, currentUser).pipe(
      mergeMap(defaultViews => {
        if (this.userService.isSuperAdmin()) {
          for (const sharedView of sharedViews) {
            const existingDefaultViewId = defaultViews.findIndex(defaultView => defaultView.id === sharedView.id);
            if (existingDefaultViewId !== -1) {
              defaultViews[existingDefaultViewId] = { ...sharedView };
            } else {
              defaultViews.push({ ...sharedView, isPinned: false });
            }
            config.views = config.views.filter(view => view.id !== sharedView.id);
          }
          defaultViews = defaultViews.filter(
            defaultView =>
              !('ownerUsername' in defaultView) ||
              !defaultView.ownerUsername ||
              defaultView.ownerUsername !== currentUser.username ||
              sharedViews.findIndex(sharedView => sharedView.id === defaultView.id) !== -1,
          );
        }
        return forkJoin({
          userConfig: this.lookupsService.setSettings<PageConfig.DataViewPageConfigSchema>(
            `user-username:${currentUser.username}`,
            `dataview3_${page}_views`,
            this.simplifyConfigForSaving(config),
            'local',
          ),
          defaultViewsConfig: this.lookupsService.setSettings<View.DefaultView[]>(
            'dataview3',
            `${page}_defaultViews`,
            defaultViews,
            'local',
          ),
        }).pipe(map(results => results.userConfig));
      }),
    );
  }

  private simplifyConfigForSaving(config: PageConfig.DataViewPageConfig): PageConfig.DataViewPageConfigSchema {
    const simplifiedViews = config.views
      .filter(view => 'isCustom' in view && view.isCustom)
      .map(view => {
        const simplifiedColumns = view.appliedColumns.map(column => ({
          id: column.id,
          width: column.width,
        }));
        const simplifiedFilters = view.appliedFilters.map(filter => ({
          id: filter.id,
          value: filter.value,
          type: filter.type,
          timezone: filter.timezone,
        }));
        return {
          id: view.id,
          appliedColumns: simplifiedColumns,
          appliedFilters: simplifiedFilters,
          mapConfig: view.mapConfig,
          label: view.label,
        };
      });
    return {
      configVersion: CURRENT_DATAVIEW_VERSION,
      pinnedViewIds: config.pinnedViewIds,
      unpinnedDefaultViewIds: config.unpinnedDefaultViewIds,
      views: simplifiedViews,
      favouriteColumnIds: config.favouriteColumns.map(favouriteColumn => favouriteColumn.id),
    };
  }

  private userHasAccessToItem(item: DataViewItemWithAccess, currentUser?: IUser) {
    const roleHasAccess =
      (!item.roleWhitelist || (currentUser?.role && item.roleWhitelist.includes(currentUser?.role as UserRole))) &&
      (!item.roleBlacklist || (currentUser?.role && !item.roleBlacklist.includes(currentUser?.role as UserRole)));
    const userHasRelevantTenantsAccess =
      !item.forTenantsWhitelist ||
      [UserRole.Administrator, 'FinanceAdmin'].includes(currentUser?.role as UserRole) ||
      (currentUser?.tenants && item.forTenantsWhitelist.every(tenant => currentUser?.tenants?.includes(tenant)));

    return roleHasAccess && userHasRelevantTenantsAccess;
  }

  private mapAvailableItems<T extends DataViewItemWithAccess>(items: T[], currentUser?: IUser) {
    const availableItems: T[] = [];
    for (const item of items) {
      if (this.userHasAccessToItem(item, currentUser)) {
        availableItems.push(item);
      }
    }
    return availableItems;
  }

  private mapCustomViews(
    views: View.ViewSchema[],
    pinnedViewIds: string[],
    availableColumns: Column.DataViewColumn[],
    availableFilters: Filter.DataViewFilter[],
  ): View.UserView[] {
    return this.mapViews(views, pinnedViewIds, [], availableColumns, availableFilters).map(
      view => ({ ...view, isCustom: true }) as View.UserView,
    );
  }

  private mapViews(
    views: View.ViewSchema[],
    pinnedViewIds: string[],
    unpinnedDefaultViewIds: string[],
    availableColumns: Column.DataViewColumn[],
    availableFilters: Filter.DataViewFilter[],
  ): Array<View.UserView | View.DefaultView> {
    const mappedViews = [];
    for (const view of views) {
      const appliedColumns = [];
      for (const column of view.appliedColumns ?? []) {
        const availableColumn = availableColumns.find(availableColumn => availableColumn.id === column.id);
        if (availableColumn) {
          appliedColumns.push({
            ...availableColumn,
            ...column,
          });
        }
      }

      const appliedFilters = [];
      for (const filter of view.appliedFilters) {
        const availableFilter = availableFilters.find(availableFilter => availableFilter.id === filter.id);
        if (availableFilter) {
          const appliedFilter = {
            ...availableFilter,
            type: filter.type,
            value: filter.value,
            timezone: filter.timezone,
          };
          if (appliedFilter.value !== null && typeof appliedFilter.value === 'object' && !Array.isArray(appliedFilter.value)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { _preset, _presetDisplayValue, ...cleanValue } = appliedFilter.value as {
              _preset: unknown;
              _presetDisplayValue: unknown;
              restOfValue: unknown;
            };
            appliedFilter.value = {
              ...cleanValue,
            };
          }
          appliedFilters.push(appliedFilter);
        }
      }

      const isPinned = (view.isPinned || pinnedViewIds.includes(view.id)) && !unpinnedDefaultViewIds.includes(view.id);

      mappedViews.push({
        ...view,
        mapConfig: 'mapConfig' in view ? view.mapConfig : DEFAULT_MAP_CONFIG,
        isPinned,
        appliedColumns,
        appliedFilters,
      });
    }
    return mappedViews;
  }
}
